body {
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-source {
  color: #fff !important;
  background: #1877b1 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#fullWidth {
  box-sizing: content-box !important;
}

/* MCQ ICON START */
#custom-styled
  .lrn_widget.lrn_mcq
  .lrn_mcqgroup
  .lrn-mcq-option.lrn_valid
  > label.lrn-label:before,
#custom-styled
  .lrn_widget.lrn_mcq
  .lrn_mcqgroup
  .lrn-mcq-option.lrn_correct
  > label.lrn-label:before {
  color: white;
  height: 16.85px;
  background-color: #3e8453;
  border-radius: 50%;
}

#custom-styled
  .lrn_widget.lrn_mcq
  .lrn_mcqgroup
  .lrn-mcq-option.lrn_incorrect
  > label.lrn-label:before {
  color: white;
  height: 16.85px;
  background-color: #b92d80;
  border-radius: 50%;
}

#custom-styled
  .lrn_widget.lrn_mcq
  .lrn_mcqgroup
  .lrn-mcq-option
  .lrn-label:before {
  padding-left: 0.2em;
}
/* MCQ ICON END */

/* MCQ - RESPONSE TEXT */
#custom-styled .lrn_widget.lrn_mcq .lrn_response_wrapper {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

/* MCQ BORDER START */
#custom-styled .lrn_widget.lrn_mcq .lrn_mcqgroup .lrn-mcq-option.lrn_incorrect {
  border: 2px solid #b92d80;
  border-radius: 4px;
  margin-bottom: 8px;
}

#custom-styled .lrn_widget.lrn_mcq .lrn_mcqgroup .lrn-mcq-option.lrn_correct,
#custom-styled .lrn_widget.lrn_mcq .lrn_mcqgroup .lrn-mcq-option.lrn_valid {
  border: 2px solid #3e8453;
  border-radius: 4px;
  margin-bottom: 8px;
}
/* MCQ BORDER END */

/* MCQ VALIDATE WRAPPER START */
#custom-styled .lrn-response-validate-wrapper.lrn_incorrect,
#custom-styled .lrn-response-validate-wrapper.lrn_correct {
  border: 1px solid transparent;
}

#custom-styled .lrn-response-validate-wrapper.lrn_incorrect:after,
#custom-styled .lrn-response-validate-wrapper.lrn_correct:after {
  content: '';
  color: transparent;
  background-color: transparent;
}

/* MCQ VALIDATE WRAPPER END */

/* CHECK ANSWER BUTTON STYLES */
#custom-styled .lrn_btn.lrn_validate {
  background-color: #1b5ba0;
  font-family: 'Open Sans';
  font-size: 14px;
  height: 36px;
  line-height: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 4px;
}

/* CHECK ANSWER BUTTON HOVERING STYLES */
#custom-styled .lrn_btn.lrn_validate:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    #1b5ba0;
}

/* CHECK ANSWER BUTTON DISABLED STYLES */
#custom-styled .lrn_btn.lrn_validate.disabled {
  background-color: rgba(0, 0, 0, 0.12);
  line-height: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
  height: 36px;
  color: rgba(0, 0, 0, 0.26);
  border-radius: 4px;
}

/* CHECK ANSWER BUTTON DISABLED HOVERING STYLES */
#custom-styled .lrn_btn.lrn_validate.disabled:hover {
  background: rgba(0, 0, 0, 0.12);
}

/* REMOVING HIGHLIGHTER WHEN ANSWER IS SELECTED */
#custom-styled .lrn_widget .lrn_valid,
#custom-styled .lrn_widget .lrn_selected {
  background-color: transparent;
}

/* CUSTOMIZING RADIO AND CHECKBOX SELECTION COLORS */
#custom-styled input[type='radio' i],
#custom-styled input[type='checkbox' i] {
  accent-color: #1b5ba0;
}

/* FILL IN THE BLANK - CLOZE TEXT INPUT FIELD ACTIVE/INACTIVE */
#custom-styled input.lrn_cloze_response.lrn_mapactive,
#custom-styled input.lrn_cloze_response {
  border-radius: 4px;
  height: 40px;
  padding: 0px 12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 200px;
  max-width: 200px;
}

/* FILL IN THE BLANK - CLOZE TEXT INPUT FIELD ACTIVE BORDER */
#custom-styled input.lrn_cloze_response.lrn_mapactive {
  border: 2px solid #1b5ba0;
}

/* FILL IN THE BLANK - CLOZE TEXT INPUT FIELD INACTIVE BORDER */
#custom-styled input.lrn_cloze_response {
  border: 2px solid #e0e0e0;
}

/* FILL IN THE BLANK - CLOZE TEXT PLAIN TEXTS */
#custom-styled .lrn_cloze.lrn_cloze_vertical_top_none .lrn_response_input {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 48px;
}

#custom-styled
  .lrn_widget
  .lrn_response.lrn_cloze:not(.lrn_formulaV2)
  .lrn_response_input
  p {
  line-height: 48px;
}

/* TEMP - CLOZE TEXT - FEEDBACK */
#custom-styled .lrn_widget .lrn_textinput.lrn_incorrect .lrn_responseIndex,
#custom-styled .lrn_widget .lrn_textinput.lrn_correct .lrn_responseIndex {
  background: none;
}
#custom-styled .lrn_widget .lrn_textinput.lrn_not_attempted .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_clozetext
  .lrn_display_correct_answer_enabled
  .lrn_textinput.lrn_incorrect
  .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_clozetext
  .lrn_display_correct_answer_enabled
  .lrn_textinput.lrn_correct
  .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_clozetext
  .lrn_display_correct_answer_enabled
  .lrn_textinput.lrn_not_attempted
  .lrn_responseIndex {
  visibility: hidden;
}

/* DROPDOWN LEGEND */
#custom-styled .lrn legend {
  width: inherit;
}

/* ORDER LIST - TEXT */
#custom-styled .lrn_stimulus_content.lrn_clearfix,
#custom-styled
  .lrn_sort_block.lrn_button.lrn_btn_sort.lrn_sortable.lrn_draggable,
#custom-styled .lrn_btn_drag.lrn_draggable,
#custom-styled
  .lrn_widget.lrn_classification
  .lrn_response
  .lrn_response_input_wrapper
  table {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* ORDER LIST - LIST ITEM */
#custom-styled
  .lrn_sort_block.lrn_button.lrn_btn_sort.lrn_sortable.lrn_draggable {
  margin-bottom: 8px;
}

/* ORDER LIST - DRAGGING ITEM */
#custom-styled
  .lrn_sort_block.lrn_button.lrn_btn_sort.lrn_sortable.lrn_draggable.lrn_drag_mirror,
#custom-styled .lrn_draggable.lrn_drag_mirror {
  background: #ffffff;
  border: 2px solid #1b5ba0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 8px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

/* ORDER LIST - LIST ITEM HOVER */
#custom-styled .lrn_widget.lrn_orderlist .lrn_btn_sort.lrn_button:hover {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.23);
  background: rgba(0, 0, 0, 0.04);
}

/* ORDER LIST - LIST ITEM BLOCK */
#custom-styled .lrn_widget.lrn_orderlist .lrn_btn_sort.lrn_sort_block {
  box-shadow: inset 0 0 0 1px #e0e0e0;
  border-radius: 4px;
  min-height: 56px;
  padding: 8px 16px;
}


#custom-styled .lrn_widget .lrn_btn_drag:active,
#custom-styled .lrn_widget .lrn_btn_drag.lrn_active,
#custom-styled .lrn_widget .lrn_btn_sort:active,
#custom-styled .lrn_widget .lrn_btn_sort.lrn_active {
  background-color: #ffffff;
  color: #383838;
  box-shadow: none;
}

#custom-styled .lrn_widget.lrn_orderlist .lrn_btn_sort:focus,
#custom-styled .lrn_widget.lrn_orderlist .lrn_btn_sort.lrn_focus {
  box-shadow: inset 0 0 0 1px #1b5ba0;
}

#custom-styled
  .lrn_btn_sort.lrn_button.lrn_drag_shadow.lrn_draggable.lrn_sort_block.lrn_sortable {
  background-color: transparent;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #1b5ba0;
  box-shadow: inset 0 0 0 2px rgba(27, 91, 160, 0.5);
  border-radius: 4px;
}

#custom-styled .lrn .lrn_sort_block .lrn_sort_gripper {
  color: rgba(0, 0, 0, 0.54);
}

#custom-styled .lrn .lrn_sort_block > .lrn_item {
  margin-top: 8px;
}

/* ORDER LIST - TEMP */
#custom-styled
  .lrn_widget.lrn_orderlist
  .lrn_btn_sort.lrn_correct
  .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_orderlist
  .lrn_btn_sort.lrn_incorrect
  .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_orderlist
  .lrn_btn_sort.lrn_suggested
  .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_orderlist.lrn_sort_block
  .lrn_btn_sort.lrn_correct
  .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_orderlist.lrn_sort_block
  .lrn_btn_sort.lrn_incorrect
  .lrn_responseIndex,
#custom-styled
  .lrn_widget.lrn_orderlist.lrn_sort_block
  .lrn_btn_sort.lrn_suggested
  .lrn_responseIndex {
  visibility: hidden;
}

/* DRAG & DROP - BUTTON STYLE AFTER DROPPED */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone
  .lrn_btn_drag.lrn_draggable {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: inline-flex;
}

/* DRAG & DROP - BUTTON STYLE IN THE LIST */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone.lrn_not_attempted_marker.lrn_response_container,
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone.lrn_not_attempted_marker.lrn_response_container.lrn-dragdrop-empty {
  max-width: 200px !important;
  width: 200px !important;
}

#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_possibilityList
  .lrn_draggable,
.lrn_widget.lrn_imageclozeassociation .lrn_possibilityList .lrn_draggable,
.lrn_widget.lrn_imageclozeassociationV2 .lrn_possibilityList .lrn_draggable {
  width: 200px;
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

/* DRAG & DROP - BUTTON HOVER */
#custom-styled .lrn_widget .lrn_btn_drag:hover {
  box-shadow: inset 0 0 0 2px #828282;
}

/* DRAG & DROP - DROP ZONE HIGHLIGHT */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone.lrn_not_attempted_marker.lrn_response_container.lrn-dragdrop-empty.lrn_highlight {
  border: 2px dashed #5987d2;
  border-radius: 4px;
}

/* DRAG & DROP - DROP ZONE HIGHLIGHT & OVER */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone.lrn_not_attempted_marker.lrn_response_container.lrn-dragdrop-empty.lrn_highlight.lrn_over {
  border: 2px dashed #5987d2;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #1b5ba0;
  border-radius: 4px;
}

/* DRAG & DROP - EMPTY DROPZONE STYLES  */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone.lrn_not_attempted_marker.lrn_response_container.lrn-dragdrop-empty {
  border: 2px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px !important;
}

/* DRAG & DROP - DROPZONE STYLES WITH BUTTON DROPPED */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone.lrn_not_attempted_marker.lrn_response_container {
  border: 2px dashed transparent;
  border-radius: 4px;
}

/* DRAG & DROP - DROPPING BUTTON FROM DROP ZONE */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_response_input
  .lrn_response_container.lrn_dragdrop.lrn_highlight.lrn_over.lrn-dragdrop-empty:not(.lrn_disabled),
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_response_input
  .lrn_response_container.lrn_dragdrop.lrn_highlight:not(.lrn_disabled):not(.lrn-dragdrop-empty) {
  border: 2px dashed #5987d2;
}

/* DRAG & DROP - SOON AFTER BUTTON DROPPED IN DROP ZONE */
#custom-styled
  .lrn_widget.lrn_clozeassociation
  .lrn_cloze_response.lrn_dragdrop.lrn_dropzone.lrn_not_attempted_marker.lrn_response_container
  .lrn_btn_drag.lrn_draggable:hover {
  box-shadow: inset 0 0 0 2px #1b5ba0;
}

/* CLASSIFICATION - POSSIBILITY LIST BUTTON */
#custom-styled
  .lrn_widget.lrn_classification
  .lrn_possibilityList
  .lrn_draggable {
  max-width: 286px;
  width: 240px;
  display: inline-flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

/* CLASSIFICATION */
#custom-styled
  .lrn_widget.lrn_classification
  table.lrn_response_input
  tbody
  tr
  td {
  height: 300px;
  transform: scale(0.95);
  border: 2px dashed #828282;
  border-radius: 4px;
  padding: 16px;
}

/* CLASSIFICATION */
#custom-styled
  .lrn_widget.lrn_classification
  .lrn_response_input
  .lrn_highlight {
  border: 2px dashed #5987d2;
}

/* CLASSIFICATION */
#custom-styled .lrn_response_input .lrn_over {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #1b5ba0;
  border-radius: 4px;
}

/* CLASSIFICATION */
#custom-styled
  .lrn_widget.lrn_classification
  table.lrn_response_input
  .lrn_btn_drag {
  max-width: 240px;
  min-width: 100%;
  display: inline-grid;
  margin: 0;
  border-radius: 4px;
  margin-bottom: 16px;
  padding-right: initial;
}

/* CLASSIFICATION */
#custom-styled .lrn_widget.lrn_classification .lrn_btn_drag {
  min-height: 64px;
}

/* LABEL IMAGE & DROP */
#custom-styled
  .lrn_widget.lrn_imageclozeassociationV2
  .lrn_response_input
  .lrn_dropzones_container
  .lrn_imagecloze_response
  .lrn_dropzone_mask {
  border: 2px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

/* LABEL IMAGE & DROP */
#custom-styled
  .lrn_widget.lrn_imageclozeassociationV2
  .lrn_response_input
  .lrn_dropzones_container
  .lrn_imagecloze_response
  .lrn_dropzone_wrapper {
  min-width: 200px;
  min-height: 42px;
}

/* LABEL IMAGE & DROP */
#custom-styled
  .lrn_widget.lrn_imageclozeassociationV2
  .lrn_response_input
  .lrn_dropzones_container
  .lrn_imagecloze_response
  .lrn_dropzone_mask.lrn_highlight {
  border: 2px dashed rgba(27, 91, 160, 0.5) !important;
}

/* LABEL IMAGE & DROP */
#custom-styled
  .lrn_widget.lrn_imageclozeassociationV2
  .lrn_response_input
  .lrn_dropzones_container
  .lrn_imagecloze_response
  .lrn_response_container
  .lrn_btn_drag {
  width: 100%;
  display: inline-flex;
  border-radius: 4px;
}

/* DRAG FOCUS */
.lrn_widget .lrn_btn_drag:focus,
.lrn_widget .lrn_btn_drag.lrn_focus,
.lrn_widget .lrn_btn_sort:focus,
.lrn_widget .lrn_btn_sort.lrn_focus {
  box-shadow: inset 0 0 0 2px #1b5ba0 !important;
}

.lrn .lrn_possibilityList,
.lrn .lrn_possibilityListContainer {
  background-color: #efeff0 !important;
  border-top: 1px solid #efeff0 !important;
}

#custom-styled .lrn_widget .lrn_correctAnswers {
  background-color: #ecf3ee;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

#custom-styled .lrn_widget .lrn_suggested_answer_item {
  background: transparent;
}

#custom-styled .lrn_widget.lrn_orderlist .lrn_btn_sort.lrn_suggested.lrn_list,
#custom-styled .lrn_widget.lrn_orderlist .lrn_btn_sort.lrn_suggested.lrn_button,
#custom-styled
  .lrn_widget.lrn_orderlist.lrn_sort_block
  .lrn_btn_sort.lrn_suggested.lrn_list,
#custom-styled
  .lrn_widget.lrn_orderlist.lrn_sort_block
  .lrn_btn_sort.lrn_suggested.lrn_button {
  background: #ecf3ee;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

#custom-styled .lrn_widget .lrn_correctAnswerList > li {
  background-color: white;
}
